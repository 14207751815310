$(document).ready(function(e)
{
    $(window).scroll(function() {
        var scroll = $(window).scrollTop();
        if (scroll > 125) {
            $('header').addClass('small-header');
        } else if (scroll < 125) {
            $('header').removeClass('small-header');
        }
    });
});